import {Injectable} from '@angular/core';

@Injectable()
export class QueryConstructorService {

  /**
   * Generate a random alias.
   */
  get alias(): string {
    return `N${Math.random().toString(36).substring(2, 5)}`;
  }

  constructQuery(query: string[], name = ``, params: any = null, paramsDef: any = null): string {
    const _query = this.constructFromArray(query, name, params, paramsDef);
    return `query ${this.alias} ${
      paramsDef && Object.keys(paramsDef).length ? `(${this.stringifyVariablesDef(paramsDef)})` : ``
    } {\n ${_query}}`
  }

  /**
   * Converts query array to string.
   * @param query
   * @param name
   * @param params
   * @param paramsDef
   */
  public constructFromArray(query: string[], name = ``, params: any = null, paramsDef: any = null): string {
    const regex = new RegExp(/"([^"]+)":/g);
    let _params: string;
    if (!paramsDef || !Object.keys(paramsDef).length) {
      _params = JSON.stringify(params ?? {}).replace(regex, '$1:').slice(1, -1);
    }
    else { _params = this.stringifyVariablesDef(paramsDef, true); }
    const _declaration = `\t${name}${params ? `(${_params})` : ''}`;
    return `${this.objectToQueryBody(this.arrayToQueryObject(query), _declaration)}`;
  }

  /**
   * Converts query object to string.
   * @param query
   * @param prefix
   * @param tabs
   * @private
   */
  private objectToQueryBody(query: any, prefix: string, tabs = `\t\t`) {
    let queryString = `${prefix} ${Object.keys(query).length ? '{' : ''} \n`;
    const isLeaf = (node: object) => !Object.keys(node).length // checks if the node is a leaf.
    Object.keys(query).forEach((key: string) => {
      // if the current node has children ( is not leaf )
      if (!isLeaf(query[key])) {
        // convert the child object into string, and add it to the current query.
        queryString += this.objectToQueryBody(query[key], `${tabs}${key}`, `${tabs}\t`);
      } else {
        queryString += `${tabs}${key}\n`
      } // if the current child does not have children ( is leaf )
    });
    return `${queryString.slice(0, -1)}\n${tabs.slice(0, -1)}${Object.keys(query).length ? '}' : ''}\n`;
  }

  /**
   * Converts query array to query object.
   * @param query
   * @private
   */
  private arrayToQueryObject(query: string[]): object {
    const queryObject = {};
    for (let item of query) {
      // let the node points to the base object.
      let node: any = queryObject;
      for (let subItem of item.split('.')) {
        node[subItem] = node[subItem] ?? {}; // add the current subItem to the current node if needed.
        node = node[subItem];  // now make the node points to current sub object.
      } // repeat.....
    }
    return queryObject;
  }


  /**
   * Converts string from camelCase to PascalCase
   * @param name
   */
  toPascalCase(name: string): string {
    return `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
  }

  /**
   * Create Mutation
   * @param name
   * @param variablesDef
   * @param query
   */
  constructMutation(name: string, variablesDef: any, query: string[]) {
    const hasParams = Object.keys(variablesDef ?? {}).length;
    return `
      mutation ${this.toPascalCase(name)}${hasParams ? '(' : ''}${
            this.stringifyVariablesDef(variablesDef)}${hasParams ? ')' : ''} {
        ${name}${hasParams ? '(' : ''}${this.stringifyVariablesDef(variablesDef, true)}${hasParams ? ')' : ''}
        ${this.objectToQueryBody(this.arrayToQueryObject(query), '')}
      }
    `
  }

  /**
   * Converts mutation params to string signature.
   * @param paramsDef
   * @param call
   * @private
   */
  public stringifyVariablesDef(paramsDef: any, call = false) {
    let _paramsDef = ``;
    Object.keys(paramsDef).forEach(param => {
      _paramsDef += call ? `${param}: $${param}, ` : `$${param}: ${paramsDef[param]}, `
    });
    return _paramsDef;
  }



}
