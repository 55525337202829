import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from 'src/app/shared/layouts/header/components/header/header.component';
import { NotificationModule } from 'src/app/kernel/notifications/notification.module';
import { DownloadFilesModule } from 'src/app/kernel/tools/download-files/download-files.module';
import { MenuModule } from 'src/app/kernel/tools/menu/menu.module';
import { TableProfilePreviewerModule } from 'src/app/kernel/tools/table-profile-previewer/TableProfilePreviewer.module';

@NgModule({
  declarations: [HeaderComponent],
    
    imports: [CommonModule, NotificationModule, DownloadFilesModule, MenuModule , TableProfilePreviewerModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
