import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'sxw-table-profile-previewer',
  templateUrl: './table-profile-previewer.component.html',
  styleUrls: ['./table-profile-previewer.component.scss']
})
export class TableProfilePreviewerComponent implements OnInit, OnChanges {
  @Input() images!: {name: string, image: string, onlineStatus?: string}[] | {name: string, image: string, onlineStatus?: string};
  _images: {name: string, image: string, onlineStatus?: string}[] = [];
  @Input() limit: number = 4;
  @Input() height: number = 30;
  @Input() width: number = 30;
  OtherUsersNames = ''
  limitIterator!: any[];
  inputNum!: number;
  tooltipOptions = {
    tooltipEvent: 'hover',
    tooltipPosition: 'bottom',
  };

  ngOnInit(): void {
    this.initComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['images']) {
      this.initComponent();
    }
  }

  initComponent() {
    this._images =  Array.isArray(this.images) ? this.images : [this.images];
    this.inputNum = this._images.length
    if(this._images.length > this.limit) {
      this.limitIterator = new Array(this.limit -1).fill(1);
      this.OtherUsersNames = this.getOtherUsersNames();
    } else {
      this.limitIterator = new Array(this._images.length).fill(1)
    }
  }

  getOtherUsersNames() {
    return this._images
      .slice(this.limitIterator.length)
      .reduce((acc, curr, i, arr) =>
        acc+=curr.name + (i == arr.length -1 ? '.' : ', ')
      ,'')
  }
}
