<div class="button-wrap {{ theme }}">
  <ng-container>
    <button
      pButton
      [type]="type"
      [class]="class"
      (click)="click()"
      [loading]="isLoading"
      [disabled]="disabled"
    >
      <sxw-svg-icon
        *ngIf="icon && iconPos === 'left'"
        [name]="icon"
        [fillColor]="leftIconFill"
      ></sxw-svg-icon>
      <span>{{ label | translate }}</span>
      <sxw-svg-icon
        *ngIf="icon && iconPos === 'right'"
        [name]="icon"
        [fillColor]="rightIconFill"
      ></sxw-svg-icon>
    </button>
  </ng-container>
</div>
