import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'sxw-overlay-panel',
  templateUrl: './overlay-panel.component.html',
  styleUrls: ['./overlay-panel.component.scss'],
})
export class OverlayPanelComponent {
  @Input() styleClass = '';
  @Output() onHide = new EventEmitter<boolean>();
  @Output() onShow = new EventEmitter<boolean>();

  @ViewChild('overlayPanel') overlayPanel!: OverlayPanel;

  toggle(event: any, target?: any) {
    this.overlayPanel.toggle(event, target);
  }
}
