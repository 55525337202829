import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const expandNavItemAnimation = trigger('expand-nav-item', [
  state('collapsed', style({ height: '0px' })),
  state('expanded', style({ height: '*' })),
  transition('collapsed => expanded', [
    style({ height: '0px' }),
    animate('0.3s ease-in-out', style({ height: '*' })),
  ]),
  transition('expanded => collapsed', [
    style({ height: '*' }),
    animate('0.3s ease-in-out', style({ height: '0px' })),
  ]),
]);
