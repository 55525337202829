<!-- Start Header -->
<div [class]="theme">
  <div class="header flex-row-between">
    <div class="search">
      <div class="search-icon">
        <img src="/assets/images/icons/magnifying-glass.svg" alt="search"/>
      </div>
      <input class="search" placeholder="Cerca nella piattaforma" />
    </div>
    <div class="right-side d-flex">
      <div class="tools-container">
        <div class="lang-icon" (click)="toggleLang()">
          <img
            src="assets/images/countries/{{
              lang === 'en' ? 'gb.svg' : 'it.svg'
            }}"
          />
        </div>
        <div class="notifications-container">
          <sxw-notifications></sxw-notifications>
        </div>
      </div>
      <div class="profile flex-row-center gap-5" (click)="menu.toggle($event)">
        <sxw-table-profile-previewer *ngIf="!noUser" [images]="user" [height]="45" [width]="45"></sxw-table-profile-previewer>
        <img *ngIf="noUser" src="/assets/images/avatars/no-user.jpg" />
        <span>{{ (authService.user$ | async)?.name }}</span>
        <sxw-menu #menu [items]="items" [popup]="true"></sxw-menu>
      </div>
    </div>
  </div>
</div>
<!-- End Header -->
