import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from '../shared/modules/overlay-panel/overlay-panel.module';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DateAgoPipe } from './pipes/date-ago/date-ago.pipe';
import { RouterModule } from "@angular/router";
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import {environment} from "src/environments/environment";

@NgModule({
  declarations: [
    NotificationsComponent,
    DateAgoPipe,
  ],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    OverlayPanelModule,
    FormsModule,
    InfiniteScrollModule,
    RouterModule,
  ],
  providers: [],
  exports: [NotificationsComponent, AngularFireMessagingModule],
})
export class NotificationModule {}
