import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse, HttpResponse,
} from '@angular/common/http';
import {Observable, tap, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthService} from "src/app/shared/auth/services/auth.service";
import {MessageService} from "src/app/kernel/tools/message/service/message.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private messageService: MessageService) {}

  handleError(code: number, message: any) {
    switch (code) {
      case 401: this.authService.logout(); this.messageService.clear(); this.messageService.error(message); break;
      case 404: this.messageService.clear(); this.messageService.error(message); break;
      case 400: this.messageService.clear(); this.messageService.error(message); break;
      case 500: this.messageService.clear(); this.messageService.error(message.message); break;
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (!(event instanceof HttpResponse)) { return ; }
        this.forEachError(event, (error) => this.handleError(error.code, error.message))
      }),
      catchError((error: HttpErrorResponse) => {
        this.handleError(error.status, error.message)
        return throwError(() => error);
      })
    );
  }

  forEachError(event: HttpResponse<any>, cb: (error: any) => void) {
    const data = Array.isArray(event.body) ? event.body : [event.body];
    data.forEach((record: any) => {
      if (!Array.isArray(record?.errors)) return;
      record.errors.forEach((error: any) => {
        if (error?.extensions?.formatted) {
          cb(error?.extensions?.formatted);
        }
      })
    })
  }

}
