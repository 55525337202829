import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/shared/layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from 'src/app/shared/auth/guards/auth.guard';

/**
 * NOTE THAT THIS FILE SHOULD ONLY CONTAIN LAZY LOADING ROUTS
 * EACH ROUTE SHOULD POINT TO A MODULE THAT CONTAINS ITS OWN ROUTES
 */
const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'company',
        loadChildren: () =>
          import('./modules/company/company.module').then(
            (m) => m.CompanyModule
          ),
      },
      {
        path: 'country',
        loadChildren: () =>
          import('./modules/country/country.module').then(
            (m) => m.CountryModule
          ),
      },
      {
        path: 'branch',
        loadChildren: () =>
          import('./modules/branch/branch.module').then((m) => m.BranchModule),
      },
      {
        path: 'affiliate',
        loadChildren: () =>
          import('./modules/affiliate/affiliate.module').then(
            (m) => m.AffiliateModule
          ),
      },
      {
        path: 'region',
        loadChildren: () =>
          import('./modules/region/region.module').then((m) => m.RegionModule),
      },
      {
        path: 'point',
        loadChildren: () =>
          import('./modules/point/point.module').then((m) => m.PointModule),
      },
      {
        path: 'area',
        loadChildren: () =>
          import('./modules/area/area.module').then((m) => m.AreaModule),
      },
      {
        path: 'broker',
        loadChildren: () =>
          import('./modules/broker/broker.module').then((m) => m.BrokerModule),
      },
      {
        path: 'user',
        children: [
          {
            path: 'external-users',
            loadChildren: () =>
              import('../cm/modules/external-users/external-users.module').then(
                (m) => m.ExternalUsersModule
              ),
          },
          {
            path: 'internalUsers',
            loadChildren: () =>
              import('../cm/modules/internal-users/internal-users.module').then(
                (m) => m.InternalUsersModule
              ),
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CmRoutingModule {}
