import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/shared/layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from 'src/app/shared/auth/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'extra-category',
        loadChildren: () =>
          import('./modules/extra-category/extra-category.module').then(
            (m) => m.ExtraCategoryModule,
          ),
      },
      {
        path: 'group-category',
        loadChildren: () =>
          import('./modules/group-category/group-category.module').then(
            (m) => m.GroupCategoryModule,
          ),
      },
      {
        path: 'kilometer',
        loadChildren: () =>
          import('./modules/kilometer/kilometer.module').then(
            (m) => m.KilometerModule,
          ),
      },
      {
        path: 'insurance',
        loadChildren: () =>
          import('./modules/insurance/insurance.module').then(
            (m) => m.InsuranceModule,
          ),
      },
      {
        path: 'vehicle-class',
        loadChildren: () =>
          import('./modules/vehicle-class/vehicle-class.module').then(
            (m) => m.VehicleClassModule,
          ),
      },
      {
        path: 'vehicleType',
        loadChildren: () =>
          import('./modules/vehicle-types/vehicle-type.module').then(
            (m) => m.VehicleTypeModule,
          ),
      },
      {
        path: 'additionalCost',
        loadChildren: () =>
          import('./modules/additionalCost/additionalCost.module').then(
            (m) => m.AdditionalCostModule,
          ),
      },
      {
        path: 'seasons',
        loadChildren: () =>
          import('./modules/seasons/seasons.module').then(
            (m) => m.SeasonsModule,
          ),
      },
      {
        path: 'damage',
        loadChildren: () =>
          import('./modules/damage-layout/damag-layout.module').then(
            (m) => m.DamageLayoutModule,
          ),
      },
      {
        path: 'optionalExtra',
        loadChildren: () =>
          import('./modules/optionalExtra/optionalExtra.module').then(
            (m) => m.OptionalExtraModule,
          ),
      },
      {
        path: 'feature',
        loadChildren: () =>
          import('./modules/feature/feature.module').then(
            (m) => m.FeatureModule,
          ),
      },
      {
        path: 'feature-category',
        loadChildren: () =>
          import('./modules/features-category/features-category.module').then(
            (m) => m.FeaturesCategoryModule,
          ),
      },
      {
        path: 'expenseType',
        loadChildren: () =>
          import('./modules/expense-type/expense-type.module').then(
            (m) => m.ExpenseTypeModule,
          ),
      },
      {
        path: 'movement-reason',
        loadChildren: () =>
          import('./modules/movement-reason/movement-reason.module').then(
            (m) => m.MovementReasonModule,
          ),
      },
      {
        path: 'damage-type',
        loadChildren: () =>
          import(
            './modules/damage-layout/modules/damage-type/damage-type.module'
          ).then((m) => m.DamageTypeModule),
      },
      {
        path: 'deadline-type',
        loadChildren: () =>
          import('./modules/deadline-type/deadline-type.module').then(
            (m) => m.DeadlineTypeModule,
          ),
      },
      {
        path: 'maintenanceType',
        loadChildren: () =>
          import('./modules/maintenance-type/maintenance-type.module').then(
            (m) => m.MaintenanceTypeModule,
          ),
      },
      {
        path: 'pricing-list',
        loadChildren: () =>
          import('./modules/pricing-list/pricing-list.module').then(
            (m) => m.PricingListModule,
          ),
      },
      {
        path: 'package',
        loadChildren: () =>
          import('./modules/plan/plan.module').then((m) => m.PlanModule),
      },
      {
        path: 'rate',
        loadChildren: () =>
          import('./modules/rate/rate.module').then((m) => m.RateModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FlmRoutingModule {}
