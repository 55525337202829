import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getNameinitials'
})
export class GetNameinitialsPipe implements PipeTransform {

  transform(value: string): string {
    if(!value) {
      return ''
    }
    const [firstName, lastName] = value.split(" ");
    return `${(firstName ? firstName[0] : '')}${(lastName ? lastName[0] : '')}`.toUpperCase();
  }

}
