import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/kernel/tools/services/local-storage.service';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/kernel/translations/translation.service';

@Component({
  selector: 'sxw-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() theme = 'sxw-theme';

  lang = 'en';
  items = [{ label: 'Logout', command: () => this.authService.logout() }];
  noUser = environment.skip_auth;
  user: { name: string; image: string; onlineStatus?: string } = {
    name: '',
    image: '',
  };

  constructor(
    private localStorageService: LocalStorageService,
    protected authService: AuthService,
    protected translationService: TranslationService,
  ) {
    this.lang = this.localStorageService.get('lang') ?? 'en';
  }

  ngOnInit(): void {
    this.authService.user$.subscribe((userInfo: {} | any) => {
      this.user = {
        name: userInfo?.name,
        image: userInfo?.image,
        onlineStatus: this.authService.getToken() ? 'ONLINE' : 'OFFLINE',
      };
    });
  }

  // TODO: Move Language support to different location
  toggleLang() {
    this.lang = this.lang === 'en' ? 'it' : 'en';
    this.localStorageService.set('lang', this.lang);
    location.reload();
  }
}
