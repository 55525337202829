import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlmRoutingModule } from './flm-routing.module';
import { SideNavService } from '../../shared/layouts/side-nav/services/side-nav.service';
import fleetServiceSidenavConfig from './fleetServiceSidenavConfig';

@NgModule({
  declarations: [],
  imports: [CommonModule, FlmRoutingModule],
  exports: [],
  providers: [],
})
export class FlmModule {
  constructor(private sideNavService: SideNavService) {
    /**
     * CODE IN HERE WILL BE EXECUTED ON APP LOAD.
     * USE IT TO INJECT YOUR SUB APP INTO THE MAIN APPLICATION
     */
    this.sideNavService.addSubMenuItems(
      'setting',
      fleetServiceSidenavConfig.fleetSTSettingsLinks
    );
  }
}
