import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from "primeng/api";
import {Menu} from "primeng/menu";

@Component({
  selector: 'sxw-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() popup = false ;
  @Input() items?: MenuItem[];
  @Input() appendTo: any = 'body';


  @ViewChild('menu') menu!: Menu;
  constructor() { }

  ngOnInit(): void {
  }

  toggle(event: any) {
    this.menu.toggle(event);
  }
}
