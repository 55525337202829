import {environment} from "../../../../environments/environment";

export function FileResourceHelper(key: string, thumb?: string | null) {
  /**
   * An array of allowed thumbnail sizes for resource images.
   * Each size is represented as a string in the format 'WxH', where W is the width and H is the height.
   * If only one dimension is specified, the other dimension is assumed to be proportional.
   */
  const ALLOWED_THUMBNAIL_SIZES = [
    '40x40', '120x120', '160x160', '240x240', '320x320', '480x480', '640x640',
    '800x800', '40x', '120x', '160x', '240x', '320x', '480x', '640x', '800x'
  ];

  if (!key) { return ''; }

  if(thumb) {
    /**
     * Creates a URL for a thumbnail image of a resource.
     * If a thumbnail size is not specified, the method uses a default size of '320x'.
     * If the specified size is not allowed, the method logs an error and uses the default size.
     *
     * @param key - the unique identifier for the resource
     * @param size - the dimensions of the thumbnail in the format 'WxH', where W is the width and H is the height (optional)
     * @returns a string representing the URL of the thumbnail image
     */
    if (ALLOWED_THUMBNAIL_SIZES.indexOf(thumb) === -1) {
      console.error(`The thumbnail size ${thumb} is not allowed, you need to use one of the allowed sizes (${
        ALLOWED_THUMBNAIL_SIZES.join(', ')})`
      );
      thumb = '320x';
    };
    return `${environment.url}/resource/thumbnail/${thumb}/${key}`;
  };

  return `${environment.url}/resource/download/${key}`;
}
