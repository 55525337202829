<div
  [ngStyle]="ngStyle"
>
  <svg
    [attr.fill]="fillColor"
    [attr.preserveAspectRatio]="'none'"
    [attr.width]="width"
    [attr.height]="height"
  >
    <use [attr.xlink:href]="svgIcon"></use>
  </svg>
</div>
