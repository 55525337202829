import { SidenavItemInterface } from '../../shared/layouts/side-nav/interfaces/side-nav-items.interface';

const authLinks: SidenavItemInterface[] = [];
const authSettingsLinks:SidenavItemInterface[] = [
  {
    id: 'Auth',
    label: 'auth.auth',
    isActive: false,
    expanded: false,
    items: [
      {
        id: 'roles',
        label: 'auth.roles',
        isActive: false,
        expanded: false,
        routerLink: '/role',
        permissions: ['VIEW_ROLE_ALL', 'VIEW_ROLE_OWN'],
      },
      {
        id: 'users',
        label: 'auth.users',
        isActive: false,
        expanded: false,
        routerLink: '/user',
        permissions: ['VIEW_USER_ALL', 'VIEW_USER_OWN'],
      },
    ],
  },
]
export default {authLinks, authSettingsLinks};
