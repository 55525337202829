import { SidenavItemInterface } from '../../shared/layouts/side-nav/interfaces/side-nav-items.interface';

const fleetSTLinks: SidenavItemInterface[] =  [
  {
    id: 'vehicle',
    label: 'fsh.vehicle',
    isActive: false,
    expanded: false,
    routerLink: '/vehicle',
    permissions: ['VIEW_VEHICLE_ALL', 'VIEW_VEHICLE_OWN']
  },
  {
    id: 'file',
    label: 'fsh.file',
    isActive: false,
    expanded: false,
    routerLink: '/file',
    permissions: ['VIEW_FILE_ALL', 'VIEW_FILE_OWN']
  },
  {
    id: 'damage',
    label: 'fsh.damage',
    isActive: false,
    expanded: false,
    routerLink: '/damagefsh',
    permissions: ['VIEW_DAMAGE_ALL', 'VIEW_DAMAGE_OWN']
  },
  {
    id: 'deadline',
    label: 'fsh.deadline',
    isActive: false,
    expanded: false,
    routerLink: '/deadline',
    permissions: ['VIEW_DEADLINE_ALL', 'VIEW_DEADLINE_OWN']
  },
  {
    id: 'maintenance',
    label: 'fsh.maintenance',
    isActive: false,
    expanded: false,
    routerLink: '/maintenance',
    permissions: ['VIEW_MAINTENANCE_ALL', 'VIEW_MAINTENANCE_OWN']
  },
  {
    id: 'fine',
    label: 'fsh.fine',
    isActive: false,
    expanded: false,
    routerLink: '/fine',
    permissions: ['VIEW_FINE_ALL', 'VIEW_FINE_OWN']
  },
  {
    id: 'expense',
    label: 'fsh.expense',
    isActive: false,
    expanded: false,
    routerLink: '/expense',
    permissions: ['VIEW_EXPENSE_ALL', 'VIEW_EXPENSE_OWN']
  },
  {
    id: 'movement',
    label: 'fsh.movement',
    isActive: false,
    expanded: false,
    routerLink: '/movement',
    permissions: ['VIEW_MOVEMENT_ALL', 'VIEW_MOVEMENT_OWN']
  },
];

export default {fleetSTLinks};
