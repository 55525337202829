import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from './settings-routing.module';
import { InvoiceModule } from './modules/invoice/invoice.module';

import { SideNavService } from 'src/app/shared/layouts/side-nav/services/side-nav.service';
import settingsSidenavConfig from './settingsSidenavConfig';
import settingsConfig from './settingConfig';
/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * NOTE THAT THIS MODULE WILL BE LOADED IN THE APP.
 * DO NOT IMPORT ANYTHING IN HERE.
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

@NgModule({
  declarations: [],
  imports: [CommonModule, SettingsRoutingModule, InvoiceModule],
  exports: [],
  providers: [],
})
export class SettingsModule {
  constructor(private sideNavService: SideNavService) {
    /**
     * CODE IN HERE WILL BE EXECUTED ON APP LOAD.
     * USE IT TO INJECT YOUR SUB APP INTO THE MAIN APPLICATION
     */
    this.sideNavService.addSubMenuItems(
      'setting',
      settingsConfig
    );

    // this.sideNavService.addSubMenuItems('setting', settingsConfig);
  }
}
