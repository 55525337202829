import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/shared/layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from 'src/app/shared/auth/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'car-rental-short/reservations',
        loadChildren: () =>
          import('./modules/reservation-v2/reservation.module').then(
            (m) => m.ReservationModule,
          ),
      },
      {
        path: 'car-rental-short/quotations',
        loadChildren: () =>
          import('./modules/quotation/quotation.module').then(
            (m) => m.QuotationModule,
          ),
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarRentalShortRoutingModule {}
