import { SidenavItemInterface } from 'src/app/shared/layouts/side-nav/interfaces/side-nav-items.interface';

const settingsConfig: SidenavItemInterface[] = [
  {
    id: 'invoiceConfig',
    label: 'settings.invoice',
    isActive: false,
    expanded: false,
    items: [
      {
        id: 'taxManagement',
        label: 'settings.taxManagement',
        isActive: false,
        expanded: false,
        routerLink: '/tax-management',
        permissions: ['VIEW_INVOICE_TAX_ALL', 'VIEW_INVOICE_TAX_OWN'],
      },
    ],
  },
];
export default settingsConfig;
