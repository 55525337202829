import { SidenavItemInterface } from '../../shared/layouts/side-nav/interfaces/side-nav-items.interface';

const carRentalShortLinks: SidenavItemInterface[] = [
  {
    id: 'Calender',
    label: 'crs.calender',
    isActive: false,
    expanded: false,
  },
  {
    id: 'Reservations',
    label: 'crs.reservations',
    isActive: false,
    expanded: false,
    routerLink: '/car-rental-short/reservations',
    permissions: ['VIEW_RESERVATION_ALL', 'VIEW_RESERVATION_OWN'],
  },
  {
    id: 'Quotations',
    label: 'crs.quotations',
    isActive: false,
    expanded: false,
    routerLink: '/car-rental-short/quotations',
    permissions: ['VIEW_QUOTATION_ALL', 'VIEW_QUOTATION_OWN'],
  },
  {
    id: 'Transactions',
    label: 'crs.transactions',
    isActive: false,
    expanded: false,
  },
];

export default carRentalShortLinks;
