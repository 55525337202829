import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavService } from 'src/app/shared/layouts/side-nav/services/side-nav.service';
import { VehicleManagementRoutingModule } from './vehicle-management-routing.module';
import vehicleManagementSidenavConfig from './vehicle-managementSidenavConfig';
/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * NOTE THAT THIS MODULE WILL BE LOADED IN THE APP.
 * DO NOT IMPORT ANYTHING IN HERE.
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

@NgModule({
  declarations: [],
  imports: [CommonModule, VehicleManagementRoutingModule],
  exports: [],
  providers: [],
})
export class VehicleManagementModule {
  constructor(private sideNavService: SideNavService) {
    /**
     * CODE IN HERE WILL BE EXECUTED ON APP LOAD.
     * USE IT TO INJECT YOUR SUB APP INTO THE MAIN APPLICATION
     */
    this.sideNavService.addSubMenuItems(
      'vehicle_management_application',
      vehicleManagementSidenavConfig.vehicleManagementLinks
    );
    this.sideNavService.addSubMenuItems(
      'setting',
      vehicleManagementSidenavConfig.vehicleManagementSettingsLinks
    );
  }
}
