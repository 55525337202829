<!-- Modal Routes -->
<sxw-popup-wrapper></sxw-popup-wrapper>
<div class="page">
  <div class="side-nav-container">
    <sxw-side-nav></sxw-side-nav>
  </div>
  <div class="content">
    <sxw-header></sxw-header>
    <div class="body-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
