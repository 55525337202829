import { Injectable } from '@angular/core';
import AuthUserModelInterface from "src/app/kernel/shared/interfaces/auth-user-model.interface";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }


  getUser(): AuthUserModelInterface | undefined {
    return <AuthUserModelInterface>{can: (permissions: string | (string | string[])[]) => true};
  }

}
