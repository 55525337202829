import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayPanelComponent } from './components/overlay-panel/overlay-panel.component';
import { OverlayPanelModule as OverlayPanelPrimeModule } from 'primeng/overlaypanel';
import { TranslationModule } from '../../../translations/modules/translation.module';

@NgModule({
  declarations: [OverlayPanelComponent],
  imports: [CommonModule, OverlayPanelPrimeModule, TranslationModule],
  exports: [OverlayPanelComponent],
})
export class OverlayPanelModule {}
