import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/shared/layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from 'src/app/shared/auth/guards/auth.guard';

/**
 * NOTE THAT THIS FILE SHOULD ONLY CONTAIN LAZY LOADING ROUTS
 * EACH ROUTE SHOULD POINT TO A MODULE THAT CONTAINS ITS OWN ROUTES
 */
const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'news',
        loadChildren: () =>
          import('./modules/article/article.module').then(
            (m) => m.ArticleModule
          ),
      },
      {
        path: 'category',
        loadChildren: () =>
          import('./modules/category/category.module').then(
            (m) => m.CategoryModule
          ),
      },
      {
        path: 'tag',
        loadChildren: () =>
          import('./modules/tag/tag.module').then((m) => m.TagModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ArticleRoutingModule {}
