import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FshRoutingModule } from './fsh-routing.module';
import { SideNavService } from '../../shared/layouts/side-nav/services/side-nav.service';
import fleetShortTermSidenavConfig from './fleetShortTermSidenavConfig';
/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * NOTE THAT THIS MODULE WILL BE LOADED IN THE APP.
 * DO NOT IMPORT ANYTHING IN HERE.
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

@NgModule({
  declarations: [],
  imports: [CommonModule, FshRoutingModule],
  exports: [],
  providers: [],
})
export class FshModule {
  constructor(private sideNavService: SideNavService) {
    /**
     * CODE IN HERE WILL BE EXECUTED ON APP LOAD.
     * USE IT TO INJECT YOUR SUB APP INTO THE MAIN APPLICATION
     */
    this.sideNavService.addSubMenuItems(
      'fsh_application',
      fleetShortTermSidenavConfig.fleetSTLinks
    );
  }
}
