import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/shared/layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from 'src/app/shared/auth/guards/auth.guard';

/**
 * NOTE THAT THIS FILE SHOULD ONLY CONTAIN LAZY LOADING ROUTS
 * EACH ROUTE SHOULD POINT TO A MODULE THAT CONTAINS ITS OWN ROUTES
 */
const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'damagefsh',
        loadChildren: () =>
          import('./modules/damage/damage.module').then((m) => m.DamageModule),
      },
      {
        path: 'movement',
        loadChildren: () =>
          import('./modules/movements/movements.module').then(
            (m) => m.MovementsModule
          ),
      },
      {
        path: 'maintenance',
        loadChildren: () =>
          import('./modules/maintenance/maintenance.module').then(
            (m) => m.MaintenanceModule
          ),
      },
      {
        path: 'deadline',
        loadChildren: () =>
          import('./modules/deadline/deadline.module').then(
            (m) => m.DeadlineModule
          ),
      },
      {
        path: 'file',
        loadChildren: () =>
          import('./modules/file/file.module').then((m) => m.FileModule),
      },
      {
        path: 'fine',
        loadChildren: () =>
          import('./modules/fine/fine.module').then((m) => m.FineModule),
      },
      {
        path: 'vehicle',
        loadChildren: () =>
          import('./modules/vehicle/vehicle.module').then(
            (m) => m.VehicleModule
          ),
      },
      {
        path: 'expense',
        loadChildren: () =>
          import('./modules/expense/expense.module').then(
            (m) => m.ExpenseModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FshRoutingModule {}
