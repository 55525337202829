export const environment = {
  firebase: {
    projectId: 'rentalab-6b6aa',
    appId: '1:749218194590:web:dce243b6168037626f078f',
    storageBucket: 'rentalab-6b6aa.appspot.com',
    apiKey: 'AIzaSyCnnldU1Oz7S8k3osD8u4IqxN-UB_Q_3g4',
    authDomain: 'rentalab-6b6aa.firebaseapp.com',
    messagingSenderId: '749218194590',
    measurementId: 'G-GX91B9F13V',
  },
  google_api_key: 'AIzaSyBWLWsLeLXb1X7rgxwhBaxl6UnCBykjl60',
  production: true,
  version: '0.0.1 (beta)',
  graphql_endpoint: 'https://testing-rentalab-graph.storexweb.team/graphql',
  url: 'https://files-rentalab-staging.storexweb.team/',
  places_key: 'AIzaSyAxFKLWDZmhVY2lL5dIUwnflxmeE7ji33s',
  skip_auth: false,
};
