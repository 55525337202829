import { Injectable } from '@angular/core';
import { IAuthUser } from 'src/app/shared/auth/interfaces/auth-user.interface';
import { LocalStorageService } from 'src/app/kernel/tools/services/local-storage.service';
import { AuthUserModel } from 'src/app/shared/auth/models/auth-user.model';
import { BehaviorSubject } from 'rxjs';
import { GraphService } from 'src/app/kernel/graphql/services/graph.service';
import { Router } from '@angular/router';
import {AuthService as BaseAuthService} from "src/app/kernel/tools/services/auth.service";

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseAuthService {
  public user$ = new BehaviorSubject<AuthUserModel | undefined>(undefined);
  public token$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    private localStorageService: LocalStorageService,
    private graphService: GraphService,
    private router: Router
  ) {
    super()
    this.setRawUser(this.localStorageService.get<IAuthUser>('user'));
    this.setToken(this.localStorageService.get<string>('token') ?? undefined);
  }

  /**
   * Sets the current authenticated user after creating the AuthUserModel object
   * @param user
   */
  setRawUser(user: IAuthUser | null): AuthService {
    this.user$.next(user ? AuthUserModel.create(user) : undefined);
    return this;
  }

  /**
   * Sets the current user, and store it to the localStorage.
   * @param user
   */
  login(user: IAuthUser, token: string) {
    this.localStorageService.set('user', user);
    this.localStorageService.set('token', token);
    this.setRawUser(user);
    this.setToken(token);
  }

  /**
   * Sets the current authenticated user.
   * @param user
   */
  setUser(user: AuthUserModel | undefined): AuthService {
    this.user$.next(user);
    return this;
  }

  /**
   * Returns the current authenticated user.
   */
  override getUser(): AuthUserModel | undefined {
    return this.user$.value;
  }

  /**
   * Sets the user auth token.
   * @param token
   */
  setToken(token: string | undefined): AuthService {
    this.token$.next(token);
    return this;
  }

  /**
   * Returns the user auth token.
   */
  getToken(): string | undefined {
    return this.token$.value;
  }

  clearUser(): AuthService {
    this.localStorageService.remove('user').remove('token');
    this.setUser(undefined).setToken(undefined);
    return this;
  }

  /**
   * Destroys the current user data.
   */
  logout(): AuthService {
    this.clearUser();
    this.router.navigate(['/login']);
    return this;
  }

  /**
   * Returns true if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return Boolean(this.user$.value) && Boolean(this.token$);
  }

  /**
   * Changes the current user active company.
   * @param company
   */
  changeCompany(company: { name: string; id: string; logo: string }) {
    if (company.id === this.getUser()?.activeCompany.id) {
      return;
    }
    this.graphService
      .constructMutation(
        'changeActiveUserCompany',
        { company: 'ID' },
        { company: company.id },
        [
          'id',
          'email',
          'name',
          'image',
          'activeCompany.name',
          'activeCompany.id',
          'activeCompany.logo',
          'availableCompanies.name',
          'availableCompanies.id',
          'availableCompanies.logo',
          'roles.id',
          'activePermissions',
          'roles.name',
          'roles.key',
        ]
      )
      .subscribe({
        next: (data: any) => {
          this.login(data.data.changeActiveUserCompany, this.getToken() ?? '');
        },
      });
  }
}
