import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from 'src/app/shared/layouts/side-nav/components/side-nav/side-nav.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconModule } from 'src/app/kernel/tools/svg-icon/svg-icon.module';
import { MenuModule } from 'src/app/kernel/tools/menu/menu.module';
import { DownloadFilesModule } from 'src/app/kernel/tools/download-files/download-files.module';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [SideNavComponent],
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		SvgIconModule,
		MenuModule,
		DownloadFilesModule,
    TooltipModule,
	],
  exports: [SideNavComponent],
})
export class SideNavModule {}
