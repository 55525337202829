import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/shared/layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from 'src/app/shared/auth/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'crm/conventions',
        loadChildren: () =>
          import('./modules/conventions/conventions.module').then(
            (m) => m.ConventionsModule
          ),
      },
      {
        path: 'sales-script',
        loadChildren: () =>
          import('./modules/sales-scripts/sales-scripts.module').then(
            (m) => m.SalesScriptsModule
          ),
      },
      {
        path: 'crm/supplierType',
        loadChildren: () =>
          import('./modules/supplier-type/supplier-type.module').then(
            (m) => m.SupplierTypeModule
          ),
      },
      {
        path: 'crm/supplier',
        loadChildren: () =>
          import('./modules/supplier/supplier.module').then(
            (m) => m.SupplierModule
          ),
      },
      {
        path: 'crm/activity',
        loadChildren: () =>
          import('./modules/activity/activity.module').then(
            (m) => m.ActivityModule
          ),
      },
      {
        path: 'crm/customer-types',
        loadChildren: () =>
          import('./modules/customer-types/customer-types.module').then(
            (m) => m.CustomerTypesModule
          ),
      },
      {
        path: 'crm/industry-sector',
        loadChildren: () =>
          import('./modules/industry-sector/industry-sector.module').then(
            (m) => m.IndustrySectorModule
          ),
      },
      {
        path: 'crm/duplicationField',
        loadChildren: () =>
          import('./modules/duplication-field/duplication-field.module').then(
            (m) => m.DuplicationFieldModule
          ),
      },
      {
        path: 'crm/dynamicField',
        loadChildren: () =>
          import('./modules/dynamic-fields/dynamic-field.module').then(
            (m) => m.DynamicFieldModule
          ),
      },
      {
        path: 'crm/tagsGroups',
        loadChildren: () =>
          import('./modules/tags-groups/tags-groups.module').then(
            (m) => m.TagsGroupsModule
          ),
      },
      {
        path: 'crm/customer',
        loadChildren: () =>
          import('./modules/customers/customer.module').then(
            (m) => m.CustomerModule
          ),
      },
      {
        path: 'crm/leads',
        loadChildren: () =>
          import('./modules/leads/leads.module').then((m) => m.LeadsModule),
      },
      {
        path: 'crm/driver',
        loadChildren: () =>
          import('./modules/driver/driver.module').then((m) => m.DriverModule),
      },
      {
        path: 'crm/customer-unblock-rules',
        loadChildren: () =>
          import(
            './modules/customer-unblock-rules/customer-unblock-rules.module'
          ).then((m) => m.CustomerUnblockRulesModule),
      },
      {
        path: 'crm/unblock-customer-request',
        loadChildren: () =>
          import(
            './modules/unblock-customer-request/unblock-customer-request.module'
          ).then((m) => m.UnblockCustomerRequestModule),
      },
      {
        path: 'crm/leadsPipeline',
        loadChildren: () =>
          import('./modules/leads-pipeline/leads-pipeline.module').then(
            (m) => m.LeadsPipelineModule
          ),
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CrmRoutingModule {}
