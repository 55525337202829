import { SidenavItemInterface } from '../../shared/layouts/side-nav/interfaces/side-nav-items.interface';

const companyManagementLinks: SidenavItemInterface[] = [];
const companyManagementSettingsLinks = [
  {
    id: 'company',
    label: 'cm.companyManagement',
    isActive: false,
    expanded: false,
    items: [
      {
        id: 'company',
        label: 'cm.company',
        isActive: false,
        expanded: false,
        routerLink: '/company',
        permissions: ['VIEW_COMPANY_ALL', 'VIEW_COMPANY_OWN'],
      },
      {
        id: 'country',
        label: 'cm.country',
        isActive: false,
        expanded: false,
        routerLink: '/country',
        permissions: ['VIEW_COUNTRY_ALL', 'VIEW_COUNTRY_OWN'],
      },
      {
        id: 'area',
        label: 'cm.area',
        isActive: false,
        expanded: false,
        routerLink: '/area',
        permissions: ['VIEW_AREA_ALL', 'VIEW_AREA_OWN'],
      },
      {
        id: 'region',
        label: 'cm.region',
        isActive: false,
        expanded: false,
        routerLink: '/region',
        permissions: ['VIEW_REGION_ALL', 'VIEW_REGION_OWN'],
      },
      {
        id: 'point',
        label: 'cm.point',
        isActive: false,
        expanded: false,
        routerLink: '/point',
        permissions: ['VIEW_POINT_ALL', 'VIEW_POINT_OWN'],
      },
      {
        id: 'branch',
        label: 'cm.branch',
        isActive: false,
        expanded: false,
        routerLink: '/branch',
        permissions: ['VIEW_BRANCH_ALL', 'VIEW_BRANCH_OWN'],
      },
      {
        id: 'affiliate',
        label: 'cm.affiliate',
        isActive: false,
        expanded: false,
        routerLink: '/affiliate',
        permissions: ['VIEW_AFFILIATE_ALL', 'VIEW_AFFILIATE_OWN'],
      },
      {
        id: 'broker',
        label: 'cm.broker',
        isActive: false,
        expanded: false,
        routerLink: '/broker',
        permissions: ['VIEW_BROKER_ALL', 'VIEW_BROKER_OWN'],
      },
      {
        id: 'externalUser',
        label: 'cm.externalUser',
        isActive: false,
        expanded: false,
        routerLink: '/user/external-users',
        permissions: ['VIEW_EXTERNAL_USER_ALL', 'VIEW_EXTERNAL_USER_OWN'],
      },
      {
        id: 'internalUser',
        label: 'cm.internalUser',
        isActive: false,
        expanded: false,
        routerLink: '/user/internalUsers',
        permissions: ['VIEW_INTERNAL_USER_ALL', 'VIEW_INTERNAL_USER_OWN'],
      },
    ],
  },
]
export default {companyManagementLinks, companyManagementSettingsLinks};
