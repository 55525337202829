<div
  class="side-nav flex-row-start"
  *ngIf="selectedMainItem$ | async as selectedMainItem"
  [ngClass]="{
    expanded:
      sideNavStatus === sideNavStatusEnum.expanded &&
      !!selectedMainItem.items.length,
    'expanded-over':
      (sideNavStatus === sideNavStatusEnum.mouseenter ||
        sideNavStatus === sideNavStatusEnum.expanded) &&
      !!selectedMainItem.items.length
  }"
  (mouseenter)="hoverExpandNavbar($event)"
  (mouseleave)="hoverExpandNavbar($event)"
>
  <div
    class="side-nav__collapse-btn"
    (click)="expandNavbar()"
    *ngIf="!!selectedMainItem.items.length"
  >
    <i
      class="pi"
      [ngClass]="
        sideNavStatus === sideNavStatusEnum.collapse ||
        sideNavStatus === sideNavStatusEnum.mouseleave
          ? 'pi-angle-right'
          : 'pi-angle-left'
      "
    >
    </i>
  </div>
  <div class="icons-bar">
    <div class="logo">
      <img
        *ngIf="!companiesShowMode && !noUser"
        src="{{
          (authService.user$ | async)?.activeCompany?.logo
            | resource: 'thumb:120x120'
        }}"
        [pTooltip]="(authService.user$ | async)?.activeCompany?.name ?? ''"
        alt="{{ (authService.user$ | async)?.activeCompany?.name }}"
        (click)="showAvailableCompanies()"
      />
      <img
        *ngIf="noUser"
        src="assets/images/logos/storexweb.png"
        [pTooltip]="'storexweb'"
        alt="no_user"
      />
    </div>
    <div class="icons-bar-content flex-col-between">
      <div class="companies-select-container" [hidden]="!companiesShowMode">
        <ng-container
          *ngFor="
            let company of (authService.user$ | async)?.availableCompanies;
            let index = index
          "
        >
          <div class="logo" (click)="changeCompany(company)">
            <img
              src="{{ company.logo | resource: 'thumb:120x120' }}"
              alt="{{ company?.name }}"
              [pTooltip]="company?.name ?? ''"
            />
          </div>
        </ng-container>
      </div>

      <!-- --------------------------------------- TOP NAV --------------------------------------- -->
      <div class="top-nav" [hidden]="companiesShowMode">
        <ul class="flex-col-center gap-10">
          <ng-container
            *ngFor="let item of mainLinks | async; let index = index"
          >
            <a
              *ngIf="item.isShown && item.direction === 'TOP'"
              [routerLink]="item.routerLink ? item.routerLink : null"
            >
              <li
                [pTooltip]="item?.tooltip ?? '' | translate"
                (click)="setSelectedItem(item.id)"
                [ngClass]="{
                  active:
                    (selectedMainItem?.id === item.id &&
                      item.routerLink &&
                      arla.isActive) ||
                    (selectedMainItem?.id === item.id && item.expanded)
                }"
                routerLinkActive="active"
                #arla="routerLinkActive"
                [routerLinkActiveOptions]="{
                  exact: item.routerLinkExact ?? false
                }"
              >
                <sxw-svg-icon
                  *ngIf="item.icon"
                  [class]="'icons'"
                  [name]="item.icon"
                  [fillColor]="
                    selectedMainItem?.id === item.id ? '#fff' : '#000'
                  "
                ></sxw-svg-icon>
              </li>
            </a>
          </ng-container>
        </ul>
      </div>

      <!-- --------------------------------------- BOTTOM NAV --------------------------------------- -->
      <div class="bottom-nav" *ngIf="!companiesShowMode">
        <ul class="flex-col-center gap-10">
          <ng-container
            *ngFor="let item of mainLinks | async; let index = index"
          >
            <a [routerLink]="item.routerLink ? item.routerLink : null">
              <li
                [pTooltip]="item?.tooltip ?? '' | translate"
                *ngIf="item.direction === 'BTN'"
                (click)="setSelectedItem(item.id)"
                [ngClass]="{
                  active:
                    (selectedMainItem?.id === item.id &&
                      item.routerLink &&
                      arla.isActive) ||
                    (selectedMainItem?.id === item.id && item.expanded)
                }"
                #arla="routerLinkActive"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{
                  exact: item.routerLinkExact ?? false
                }"
              >
                <sxw-svg-icon
                  [class]="'icons'"
                  *ngIf="item.icon"
                  [name]="item.icon"
                  [fillColor]="
                    selectedMainItem?.id === item.id ? '#fff' : '#000'
                  "
                ></sxw-svg-icon>
              </li>
            </a>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  <div class="links-nav hide-mobile">
    <img
      src="assets/images/logos/storexweb-name.png"
      style="width: 150px; margin: 8px 15px"
    />
    <div class="items flex-col-center">
      <ul>
        <ng-container
          *ngFor="let subItem of selectedMainItem.items"
          [ngTemplateOutlet]="subItems"
          [ngTemplateOutletContext]="{ $implicit: subItem, depth: 0 }"
        >
        </ng-container>
      </ul>
    </div>
  </div>
</div>

<!-- Render SubMenuItems and nested SubMenuItems -->
<ng-template #subItems let-subItem let-depth="depth">
  <li
    class="flex-row-between gap-30 nav-item"
    *ngIf="subItem.isShown"
    (click)="toggleExpandMenuItem(subItem)"
    [ngClass]="{ open: subItem.expanded }"
    [routerLink]="subItem.routerLink ? subItem.routerLink : null"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: false }"
  >
    <span translate>sidenav.{{ subItem.label }}</span>
    <div
      class="expand-icon"
      [ngClass]="{ open: subItem.expanded }"
      *ngIf="subItem?.items?.length"
    >
      <sxw-svg-icon name="down-arrow" width="10"></sxw-svg-icon>
    </div>
  </li>
  <ng-container *ngIf="subItem.items.length">
    <ul
      class="sub-menu sub-menu-{{depth | json}}"
      [@expand-nav-item]="subItem.expanded ? 'expanded' : 'collapsed'"
      [ngClass]="{ open: subItem.expanded }"
    >
      <ng-container
        *ngFor="let item of subItem.items"
        [ngTemplateOutlet]="subItems"
        [ngTemplateOutletContext]="{ $implicit: item, depth: depth+1 }"
      >
      </ng-container>
    </ul>
  </ng-container>
</ng-template>
