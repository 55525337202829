import { SidenavItemInterface } from '../../shared/layouts/side-nav/interfaces/side-nav-items.interface';
const fleetSTSettingsLinks: SidenavItemInterface[] = [
  {
    id: 'short-term',
    label: 'settings.short',
    isActive: false,
    expanded: false,
    items: [
      {
        id: 'vehicleType',
        label: 'flm.vehicleType',
        isActive: false,
        expanded: false,
        routerLink: '/vehicleType',
        permissions: ['VIEW_VEHICLE_TYPE_ALL', 'VIEW_VEHICLE_TYPE_OWN'],
      },
      {
        id: 'feature',
        label: 'flm.feature',
        isActive: false,
        expanded: false,
        items: [
          {
            id: 'feature-category',
            label: 'flm.featuresCategory',
            isActive: false,
            expanded: false,
            routerLink: '/feature-category',
            permissions: [
              'VIEW_FEATURE_CATEGORY_ALL',
              'VIEW_FEATURE_CATEGORY_OWN',
            ],
          },
          {
            id: 'feature',
            label: 'flm.feature',
            isActive: false,
            expanded: false,
            routerLink: '/feature',
            permissions: ['VIEW_FEATURE_ALL', 'VIEW_FEATURE_OWN'],
          },
        ],
        permissions: [
          'VIEW_FEATURE_ALL',
          'VIEW_FEATURE_OWN',
          'VIEW_FEATURE_CATEGORY_ALL',
          'VIEW_FEATURE_CATEGORY_OWN',
        ],
      },
      {
        id: 'vehicle-groups',
        label: 'flm.vehicleGroup',
        items: [
          {
            id: 'group-category',
            label: 'flm.groupCategory',
            isActive: false,
            expanded: false,
            routerLink: '/group-category',
            permissions: ['VIEW_GROUP_CATEGORY_ALL', 'VIEW_GROUP_CATEGORY_OWN'],
          },
          {
            id: 'vehicle-class',
            label: 'flm.vehicleGroup',
            isActive: false,
            expanded: false,
            routerLink: '/vehicle-class',
            permissions: ['VIEW_VEHICLE_CLASS_ALL', 'VIEW_VEHICLE_CLASS_OWN'],
          },
        ],
        permissions: [
          'VIEW_GROUP_CATEGORY_ALL',
          'VIEW_GROUP_CATEGORY_OWN',
          'VIEW_VEHICLE_CLASS_ALL',
          'VIEW_VEHICLE_CLASS_OWN',
        ],
      },
      {
        id: 'plan',
        label: 'flm.plans',
        isActive: false,
        expanded: false,
        routerLink: '/package',
        permissions: ['VIEW_PLAN_ALL', 'VIEW_PLAN_OWN'],
      },
      {
        id: 'insurance',
        label: 'flm.insurances',
        isActive: false,
        expanded: false,
        routerLink: '/insurance',
        permissions: ['VIEW_INSURANCE_ALL', 'VIEW_INSURANCE_OWN'],
      },
      {
        id: 'extra',
        label: 'flm.extras',
        items: [
          {
            id: 'extra-category',
            label: 'flm.extraCategory',
            isActive: false,
            expanded: false,
            routerLink: '/extra-category',
            permissions: ['VIEW_EXTRA_CATEGORY_ALL', 'VIEW_EXTRA_CATEGORY_OWN'],
          },
          {
            id: 'optionalExtra',
            label: 'flm.extras',
            isActive: false,
            expanded: false,
            routerLink: '/optionalExtra',
            permissions: ['VIEW_OPTIONAL_EXTRA_ALL', 'VIEW_OPTIONAL_EXTRA_OWN'],
          },
        ],
        permissions: [
          'VIEW_EXTRA_CATEGORY_ALL',
          'VIEW_EXTRA_CATEGORY_OWN',
          'VIEW_OPTIONAL_EXTRA_ALL',
          'VIEW_OPTIONAL_EXTRA_OWN',
        ],
      },
      {
        id: 'additionalCost',
        label: 'flm.additionalCost',
        isActive: false,
        expanded: false,
        routerLink: '/additionalCost',
        permissions: ['VIEW_ADDITIONAL_COST_ALL', 'VIEW_ADDITIONAL_COST_OWN'],
      },
      {
        id: 'rate',
        label: 'flm.rates',
        isActive: false,
        expanded: false,
        routerLink: '/rate',
        permissions: ['VIEW_RATE_ALL', 'VIEW_RATE_OWN'],
      },
      {
        id: 'seasons',
        label: 'flm.seasons',
        isActive: false,
        expanded: false,
        routerLink: '/seasons',
        permissions: ['VIEW_SEASON_ALL', 'VIEW_SEASON_OWN'],
      },
    ],
  },
  {
    id: 'fleet',
    label: 'settings.fleet',
    items: [
      {
        id: 'types',
        label: 'fleet.types',
        isActive: false,
        expanded: false,
        items: [
          {
            id: 'deadline-type',
            label: 'fleet.deadlineType',
            isActive: false,
            expanded: false,
            routerLink: '/deadline-type',
            permissions: ['VIEW_DEADLINE_TYPE_ALL', 'VIEW_DEADLINE_TYPE_OWN'],
          },
          {
            id: 'maintenanceType',
            label: 'fleet.maintenanceType',
            isActive: false,
            expanded: false,
            routerLink: '/maintenanceType',
            permissions: [
              'VIEW_MAINTENANCE_TYPE_ALL',
              'VIEW_MAINTENANCE_TYPE_OWN',
            ],
          },
          {
            id: 'expenseType',
            label: 'fleet.expenseType',
            isActive: false,
            expanded: false,
            routerLink: '/expenseType',
            permissions: ['VIEW_EXPENSE_TYPE_ALL', 'VIEW_EXPENSE_TYPE_OWN'],
          },
        ],
      },
      {
        id: 'movement-reason',
        label: 'fleet.movementReason',
        isActive: false,
        expanded: false,
        routerLink: '/movement-reason',
        permissions: ['VIEW_MOVEMENT_REASON_ALL', 'VIEW_MOVEMENT_REASON_OWN'],
      },
    ],
  },
];
export default { fleetSTSettingsLinks };
