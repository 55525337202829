import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';

import { NgxHttpLoaderModule } from 'ngx-http-loader';

// ===================== SUB APPLICATIONS { =====================
import { AuthModule } from 'src/app/applications/auth/auth.module';
import { CmModule } from 'src/app/applications/cm/cm.module';
import { CoreModule } from 'src/app/core/core.module';
import { FlmModule } from './applications/flm/flm.module';
import { UmModule } from './applications/um/um.module';
import { FshModule } from './applications/fsh/fsh.module';
import { ArticleModule } from './applications/article/article.module';
import { FleetLTModule } from './applications/fleet-lt/fleet-lt.module';
import { SettingsModule } from './applications/settings/settings.module';
import { VehicleManagementModule } from './applications/vehicle-management/vehicle-management.module';
import { CrmModule } from './applications/crm/crm.module';
import { GrentModule } from './applications/grent/grent.module';
import { DashboardLayoutModule } from 'src/app/shared/layouts/dashboard-layout/dashboard-layout.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from 'src/app/shared/auth/interceptors/JwtInterceptor';
import { AuthService as AppAuthService } from 'src/app/shared/auth/services/auth.service';
import { AuthService } from 'src/app/kernel/tools/services/auth.service';
import { MessageModule } from 'src/app/kernel/tools/message/message.module';
import { CarRentalShortModule } from './applications/car-rental-short/car-rental-short.module';
// ===================== } SUB APPLICATIONS =====================

@NgModule({
  declarations: [AppComponent],
  imports: [
    // ================== Angular Defaults { ====================
    BrowserModule,
    AppRoutingModule,
    // ================== } Angular Defaults ====================

    // ===================== CORE MODULE { ======================
    MessageModule,
    CoreModule,
    // ===================== } CORE MODULE ======================

    // =================== SUB APPLICATIONS { ===================
    AuthModule,
    CmModule,
    ArticleModule,
    CrmModule,
    FlmModule,
    FshModule,
    VehicleManagementModule,
    SettingsModule,
    UmModule,
    CarRentalShortModule,
    // =================== } SUB APPLICATIONS ===================
    DashboardLayoutModule,
    NgxHttpLoaderModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    { provide: AuthService, useClass: AppAuthService },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
