import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutComponent } from 'src/app/shared/layouts/dashboard-layout/dashboard-layout.component';
import { HeaderModule } from 'src/app/shared/layouts/header/header.module';
import { SideNavModule } from 'src/app/shared/layouts/side-nav/side-nav.module';
import { RouterModule } from '@angular/router';
import {PopupWrapperModule} from "src/app/kernel/popups/popup-wrapper/popup-wrapper.module";

@NgModule({
  declarations: [DashboardLayoutComponent],
  imports: [
    CommonModule,
    HeaderModule,
    SideNavModule,
    RouterModule,
    PopupWrapperModule
  ],
  exports: [DashboardLayoutComponent],
})
export class DashboardLayoutModule {}
