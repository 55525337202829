import { SidenavItemInterface } from 'src/app/shared/layouts/side-nav/interfaces/side-nav-items.interface';

const vehicleManagementLinks: SidenavItemInterface[] = [];
const vehicleManagementSettingsLinks = [
  {
    id: 'vehicle_management_application',
    label: 'settings.jato',
    isActive: false,
    expanded: false,
    items: [
      {
        id: 'brand',
        label: 'vehicleManagement.brand',
        isActive: false,
        expanded: false,
        routerLink: '/brand',
        permissions: ['VIEW_BRAND_ALL', 'VIEW_BRAND_OWN'],
      },
      {
        id: 'model',
        label: 'vehicleManagement.model',
        isActive: false,
        expanded: false,
        routerLink: '/model',
        permissions: ['VIEW_MODEL_ALL', 'VIEW_MODEL_OWN'],
      },
      {
        id: 'fuelType',
        label: 'vehicleManagement.fuelType',
        isActive: false,
        expanded: false,
        routerLink: '/fuel-type',
        permissions: ['VIEW_FUEL_TYPE_ALL', 'VIEW_FUEL_TYPE_OWN'],
      },
      {
        id: 'version',
        label: 'vehicleManagement.version',
        isActive: false,
        expanded: false,
        routerLink: '/version',
        permissions: ['VIEW_VERSION_ALL', 'VIEW_VERSION_OWN'],
      },
    ],
  },
]
export default {vehicleManagementLinks, vehicleManagementSettingsLinks};
