import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrmRoutingModule } from './crm-routing.module';
import { SideNavService } from '../../shared/layouts/side-nav/services/side-nav.service';
import crmServiceSidenavConfig from './crmServiceSidenavConfig';

@NgModule({
  declarations: [],
  imports: [CommonModule, CrmRoutingModule],
})
export class CrmModule {
  constructor(private sideNavService: SideNavService) {
    /**
     * CODE IN HERE WILL BE EXECUTED ON APP LOAD.
     * USE IT TO INJECT YOUR SUB APP INTO THE MAIN APPLICATION
     */
    this.sideNavService.addSubMenuItems(
      'crm',
      crmServiceSidenavConfig.crmLinks
    );
    this.sideNavService.addSubMenuItems(
      'setting',
      crmServiceSidenavConfig.settingLinks
    );
  }
}
