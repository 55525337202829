import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceRoutingModule } from './invoice-routing.module';
/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * NOTE THAT THIS MODULE WILL BE LOADED IN THE APP.
 * DO NOT IMPORT ANYTHING IN HERE.
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    InvoiceRoutingModule,
  ],
  exports: [
  ],
  providers:[]
})
export class InvoiceModule {
  constructor() {
    /**
     * CODE IN HERE WILL BE EXECUTED ON APP LOAD.
     * USE IT TO INJECT YOUR SUB APP INTO THE MAIN APPLICATION
     */
  }
}
