import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/shared/layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from 'src/app/shared/auth/guards/auth.guard';

/**
 * NOTE THAT THIS FILE SHOULD ONLY CONTAIN LAZY LOADING ROUTS
 * EACH ROUTE SHOULD POINT TO A MODULE THAT CONTAINS ITS OWN ROUTES
 */
const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'fuel-type',
        loadChildren: () =>
          import('./modules/fuel-type/fuel-type.module').then(
            (m) => m.FuelTypeModule
          ),
      },
      {
        path: 'brand',
        loadChildren: () =>
          import('./modules/brand/brand.module').then((m) => m.BrandModule),
      },
      {
        path: 'model',
        loadChildren: () =>
          import('./modules/model/model.module').then((m) => m.ModelModule),
      },
      {
        path: 'version',
        loadChildren: () =>
          import('./modules/version/version.module').then(
            (m) => m.VersionModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VehicleManagementRoutingModule {}
