import { SidenavItemInterface } from 'src/app/shared/layouts/side-nav/interfaces/side-nav-items.interface';

const articleLinks: SidenavItemInterface[] = [
  // {
  //   id: 'news',
  //   label: 'article.news',
  //   isActive: false,
  //   expanded: false,
  //   routerLink: '/news',
  //   permissions: ['VIEW_ARTICLE']
  // }
];
const articleSettingsLinks = [
  {
    id: 'news',
    label: 'article.news',
    isActive: false,
    expanded: false,
    items: [
      {
        id: 'category',
        label: 'article.category',
        isActive: false,
        expanded: false,
        routerLink: '/category',
        permissions: ['VIEW_ARTICLE_CATEGORY_ALL', 'VIEW_ARTICLE_CATEGORY_OWN'],
      },
      {
        id: 'tag',
        label: 'article.tag',
        isActive: false,
        expanded: false,
        routerLink: '/tag',
        permissions: ['VIEW_ARTICLE_TAG_ALL', 'VIEW_ARTICLE_TAG_OWN'],
      },
    ],
  },
]
export default {articleLinks, articleSettingsLinks};
