<div
  style="display: flex; justify-content: center"
>
  <div
  *ngIf="_images && _images.length"
  class="container"
>
<!--  I used TemplateOutlet to satisfy typeScript because the Input images can be two types and the HTML template always gives an error -->
  <ng-container *ngFor="let _ of limitIterator; index as i">
    <div
      [ngStyle]="{
        width: width +'px',
        height: height +'px',
        left: -i * 15 + 'px',
        'z-index': _images.length - i
      }"
      class="img-container" *ngIf="_images[i] as userSetting"
    >
      <div class="online-status online-status--{{userSetting.onlineStatus}} online-status--small profile-online-status"
           *ngIf="userSetting?.image && userSetting?.onlineStatus && userSetting?.onlineStatus !== 'OFFLINE'"
      ></div>

      <ng-container
        [ngTemplateOutlet]="userSetting?.image ? hasImage : noImage"
        [ngTemplateOutletContext]="{ $implicit: userSetting }"
      ></ng-container>
    </div>
  </ng-container>
  <div
    *ngIf="_images.length > limit"
    class="load-more-image_container"
    [ngStyle]="{
        width: width +'px',
        height: height +'px',
        left: ((limitIterator.length -1) * 26) + 'px',
        'z-index': _images.length - limit
      }"
  >
    <p
      class="load-more-image"
      [pTooltip]="OtherUsersNames"
      [tooltipOptions]="tooltipOptions"
    >
      +{{(_images.length - limit +1)}}
    </p>
  </div>
</div>
</div>

<ng-template #noImage let-userSettings>
  <p  [pTooltip]="userSettings.name" [tooltipOptions]="tooltipOptions" class="fallback-container">
    {{ userSettings.name | getNameinitials }}
  </p>
</ng-template>

<ng-template #hasImage let-userSettings>
  <img
    class="preview-image"
    [src]="userSettings.image | resource"
    [pTooltip]="userSettings.name"
    [tooltipOptions]="tooltipOptions"
  />
</ng-template>
