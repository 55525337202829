import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SideNavService } from '../../shared/layouts/side-nav/services/side-nav.service';
import { CarRentalShortRoutingModule } from './car-rental-short-routing.module';
import carRentalShortLinks from './carRentalShortServiceSidenavConfig';

@NgModule({
  declarations: [],
  imports: [CommonModule, CarRentalShortRoutingModule],
})
export class CarRentalShortModule {
  constructor(private sideNavService: SideNavService) {
    /**
     * CODE IN HERE WILL BE EXECUTED ON APP LOAD.
     * USE IT TO INJECT YOUR SUB APP INTO THE MAIN APPLICATION
     */
    this.sideNavService.addSubMenuItems(
      'car-rental-short',
      carRentalShortLinks,
    );
  }
}
