import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslationModule} from "../../translations/modules/translation.module";
import {TableProfilePreviewerComponent} from "./components/table-profile-previewer.component";
import {DownloadFilesModule} from "../download-files/download-files.module";
import { TooltipModule } from 'primeng/tooltip';
import { GetNameinitialsPipe } from './pipes/get-nameinitials.pipe';


@NgModule({
  declarations: [
    TableProfilePreviewerComponent,
    GetNameinitialsPipe
  ],
  imports: [
    CommonModule,
    TranslationModule,
    DownloadFilesModule,
    TooltipModule,
  ],
  exports: [TableProfilePreviewerComponent]
})
export class TableProfilePreviewerModule { }
