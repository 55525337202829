import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isAuthenticated() || environment.skip_auth) {
      // User is authenticated, allow access
      if (
        !this.authService.getUser()?.can(next.data['Permissions'] ?? []) ??
        false
      ) {
        return this.router.parseUrl('/403');
      }
      return true;
    } else {
      // User is not authenticated, redirect to login page
      return this.router.parseUrl('/login');
    }
  }
}
