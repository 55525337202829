import {Pipe, PipeTransform} from '@angular/core';
import {FileResourceHelper} from "../../../shared/helpers/file-resource-helper";

@Pipe({
  name: 'resource',
})
export class ResourcePip implements PipeTransform {
  /**
   * Transforms a resource key and optional arguments into a string representing the resource URL.
   * If the first argument starts with 'thumb', the method returns the URL for a thumbnail image of the resource.
   * Otherwise, the method returns the URL for the full-size resource.
   *
   * @param key - the unique identifier for the resource
   * @param args - optional arguments to modify the resource URL
   * @returns a string representing the URL of the resource
   */
  transform(key: string | undefined, ...args: any[]): string {
    if (!key) { return ''; }
    // Check if the first argument starts with 'thumb'
    if (args[0] && args[0].startsWith('thumb')) {
      // If so, extract the dimensions of the thumbnail from the argument and return the URL for the thumbnail
      return FileResourceHelper(key, args[0].split(':')[1] !== undefined ? args[0].split(':')[1] : '320x');
    }
    // Otherwise, return the URL for the full-size resource
    return FileResourceHelper(key);
  }


}
