import { Injectable } from '@angular/core';
import { SidenavItemInterface } from 'src/app/shared/layouts/side-nav/interfaces/side-nav-items.interface';
import { SidenavModel } from 'src/app/shared/layouts/side-nav/models/sidenav.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  private mainLinks: SidenavModel[] = [];

  private selectedMainLink$ = new BehaviorSubject<SidenavModel | undefined>(
    this.mainLinks.find((link) => link.expanded),
  );

  private rootSidenavItem: SidenavModel = new SidenavModel({
    id: 'root',
    items: [],
    label: 'ROOT',
    icon: '',
  });

  mainLinks$ = new BehaviorSubject<SidenavModel[]>([]);

  constructor(private authService: AuthService) {
    authService.user$.subscribe({
      next: (user) => {
        if (!user) {
          return this.resetSideNav();
        }
        const res = this.mainLinks.map((link) => link.checkPermissions(user));
        this.mainLinks$.next(res);
      },
    });
  }

  get currentActivatedMainLink() {
    return this.selectedMainLink$.value;
  }

  /** Get all main links */
  getMainLinks(): SidenavModel[] {
    return this.mainLinks;
  }

  /**
   * This will reset selectedMainLink (after user logout).
   * TODO: Refactor sidenav to be called once in App root
   */
  resetSideNav() {
    this.selectedMainLink$.value?.unExpandAllItems();
    this.selectedMainLink$.next(undefined);
  }

  /**
   * Set Main links
   * @param mainLinks
   */
  setMainLinks(mainLinks: SidenavItemInterface[]): void {
    this.mainLinks = this.rootSidenavItem.setMainLinks(mainLinks);
    this.mainLinks.map((i) => i.checkPermissions(this.authService.getUser()));
  }

  /** get current Main Link that have isShown and expanded */
  getSelectedMainItem(): Observable<SidenavModel | undefined> {
    return this.selectedMainLink$.asObservable();
  }

  /**
   * set selected main link
   * @param id
   * @param routerLink
   */
  setSelectedMainLink(
    id: string | null = null,
    routerLink?: string | string[],
  ): void {
    routerLink = Array.isArray(routerLink)
      ? routerLink
      : routerLink?.split('/');
    let selectedMainLink: SidenavModel | undefined;
    if (id) {
      selectedMainLink = this.setSelectMainItemWithId(id);
    } else if (routerLink) {
      selectedMainLink = this.setSelectMainItemWithoutId(routerLink);
    }
    this.selectedMainLink$.next(selectedMainLink);
  }

  /**
   * set selected main link if it isShown and expanded
   * if not expanded set expanded: true for first element has isShown
   * @private
   */
  private setSelectMainItemWithoutId(
    routerLink: string[],
  ): SidenavModel | undefined {
    let selectedMainLink = this.mainLinks.find((link) => {
      if (link.getMatchRate(routerLink)) {
        return true;
      } else {
        return link.isShown && link.expanded;
      }
    });
    if (!selectedMainLink) {
      selectedMainLink = this.mainLinks.find((link) => link.isShown);
      selectedMainLink && (selectedMainLink.expanded = true);
    }
    return selectedMainLink;
  }

  /**
   * Select Main item with ID
   * @param id
   * @private
   */
  private setSelectMainItemWithId(id: string): SidenavModel | undefined {
    const selectedMainLink = this.mainLinks.find((link) => link.id === id);
    selectedMainLink?.toggleExpandItem();
    return selectedMainLink;
  }

  /**
   * Add sub menu items
   * @param id
   * @param items
   */
  addSubMenuItems(id: string, items: SidenavItemInterface[]): void {
    const mainLink = this.mainLinks.find((link) => link.id === id);
    mainLink
      ?.setSubMainLinks(items)
      .checkPermissions(this.authService.getUser());
    this.mainLinks$.next(this.mainLinks);
  }

  /**
   * expand an item or activate router link
   * @param item
   */
  toggleExpandItem(item: SidenavModel) {
    if (item.expanded) {
      return item.unExpandAllItems();
    }
    this.rootSidenavItem.unExpandAllItems();
    item.toggleExpandItem();
  }

  /**
   * Check for current url and compare all sidenav items with it
   * @param routerLink
   */
  searchMenuActivatedRoute(routerLink: string | string[]): void {
    const routeArray = Array.isArray(routerLink)
      ? routerLink
      : routerLink.split('/');
    this.rootSidenavItem.activateItem(routeArray);
    this.setSelectedMainLink(null, routeArray);
  }
}
