import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadFilesComponent } from './components/download-files/download-files.component';
import { ButtonModule } from '../../shared/modules/button/button.module';
import { SpinnerModule } from '../../shared/modules/spinner/spinner.module';
import {FilePipe} from "src/app/kernel/tools/download-files/pipes/file.pipe";
import {ResourcePip} from "src/app/kernel/tools/download-files/pipes/resource.pip";
import { ResourceNamePipe } from '../../shared/pipes/resource-name.pipe';
import { ResourceTypePipe } from '../../shared/pipes/resource-type.pipe';

@NgModule({
  declarations: [DownloadFilesComponent, FilePipe, ResourcePip,ResourceNamePipe,ResourceTypePipe],
  imports: [CommonModule, ButtonModule, SpinnerModule],
  exports: [DownloadFilesComponent, FilePipe, ResourcePip,ResourceNamePipe,ResourceTypePipe],
})
export class DownloadFilesModule {}
