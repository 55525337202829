import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
})
export class DateAgoPipe implements PipeTransform {
  transform(value: Date | undefined): any {
    if (!value) return '';
    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
    const SECONDS_IN_MINUTE = 60;
    const SECONDS_IN_HOUR = 3600;
    const SECONDS_IN_DAY = 86400;
    const SECONDS_IN_WEEK = 604800;

    if (seconds < SECONDS_IN_MINUTE) {
      return 'now';
    }

    if (seconds >= SECONDS_IN_MINUTE && seconds < SECONDS_IN_HOUR) {
      return `${Math.floor(seconds / SECONDS_IN_MINUTE)} minutes ago`;
    }

    if (seconds >= SECONDS_IN_HOUR && seconds < SECONDS_IN_DAY) {
      return `${Math.floor(seconds / SECONDS_IN_HOUR)} hours ago`;
    }

    if (seconds >= SECONDS_IN_DAY && seconds < SECONDS_IN_WEEK) {
      return `${Math.floor(seconds / SECONDS_IN_DAY)} days ago`;
    }

    return `${Math.floor(seconds / SECONDS_IN_WEEK)} weeks ago`;
  }
}
