import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from '../../../../shared/layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from '../../../../shared/auth/guards/auth.guard';

/**
 * NOTE THAT THIS FILE SHOULD ONLY CONTAIN LAZY LOADING ROUTS
 * EACH ROUTE SHOULD POINT TO A MODULE THAT CONTAINS ITS OWN ROUTES
 */
const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'invoice-configuration',
        loadChildren: () =>
          import('./modules/configuration/configuration.module').then(
            (m) => m.ConfigurationModule
          ),
      },
      {
        path: 'currency-management',
        loadChildren: () =>
          import('./modules/currency/currency.module').then(
            (m) => m.CurrencyModule
          ),
      },
      {
        path: 'tax-management',
        loadChildren: () =>
          import('./modules/tax/tax.module').then((m) => m.TaxModule),
      },
      {
        path: 'balance-account',
        loadChildren: () =>
          import('./modules/balance-account/balance-account.module').then(
            (m) => m.BalanceAccountModule
          ),
      },
      {
        path: 'electronic-invoice',
        loadChildren: () =>
          import('./modules/electronic/electronic.module').then(
            (m) => m.ElectronicModule
          ),
      },
      {
        path: 'company-invoice',
        loadChildren: () =>
          import('./modules/company/company.module').then(
            (m) => m.CompanyModule
          ),
      },
      {
        path: 'payment-methods',
        loadChildren: () =>
          import('./modules/payment-methods/payment-methods.module').then(
            (m) => m.PaymentMethodsModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InvoiceRoutingModule {}
