import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotAuthorizedComponent } from 'src/app/core/error-pages/not-authorized/not-authorized.component';

const routes: Routes = [
  {
    path: 'popups',
    loadChildren: () =>
      import('./shared/popups/popup.module').then((m) => m.PopupModule),
    outlet: 'popups',
  },
  {
    path: '403',
    component: NotAuthorizedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
