<button type="button" class="notification-btn" (click)="openNotificationsPanel($event)">
  <ng-container
    *ngTemplateOutlet="notificationsTriggerTemplate ? notificationsTriggerTemplate : defaultNotificationsTriggerTemplate"
  ></ng-container>
  <div class="new-notifications-count" *ngIf="newNotificationsCount">{{newNotificationsCount}}</div>
</button>

<sxw-overlay-panel #notificationPanel>
  <div class="notifications">
    <ng-container
      *ngTemplateOutlet="notificationsHeaderTemplate ? notificationsHeaderTemplate : defaultNotificationsHeaderTemplate"
    ></ng-container>
    <ng-container *ngIf="notifications as notifications">
      <ul class="notifications__list" *ngIf="notifications.length" infiniteScroll [scrollWindow]="false"
        [infiniteScrollDistance]="0" (scrolled)="loadNextPage()" [infiniteScrollDisabled]="totalPages <= page">
        <ng-container *ngFor="let notification of notifications">
          <li class="notification-item" (click)="handleNotificationClick(notification)">
            <ng-container *ngTemplateOutlet="notificationTemplate ? notificationTemplate : defaultNotificationTemplate;
               context: {notification}"
            ></ng-container>
          </li>
        </ng-container>
      </ul>
    </ng-container>
    <div *ngIf="loading" class="notifications__loading-container">
      <img src="/assets/images/icons/loading-spinner.svg" alt="loading..." class="notifications__loading"/>
    </div>
  </div>
</sxw-overlay-panel>

<ng-template #defaultNotificationsTriggerTemplate>
  <img class="notification-btn__img" src="/assets/images/icons/alarm.svg"/>
</ng-template>

<ng-template #defaultNotificationsHeaderTemplate>
  <h2 class="notifications__header">Notifications</h2>
</ng-template>

<ng-template #defaultNotificationTemplate let-notification="notification">
  <div class="notification-item__details">
    <b class="notification-item__title">{{ notification?.title }}</b>
    <span class="notification-item__text">{{ notification?.text }}</span>
    <span class="notification-item__date">{{ notification?.created_at | dateAgo }}</span>
  </div>
  <div class="notification-item__seen" *ngIf="!notification?.clicked_at"></div>
</ng-template>
