import {Component, ContentChild, inject, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { NotificationService } from '../../services/notifications/notification.service';
import {
  OverlayPanelComponent
} from '../../../shared/modules/overlay-panel/components/overlay-panel/overlay-panel.component';
import { takeUntil } from 'rxjs/operators';
import { Subject, take } from 'rxjs';
import { NotificationInterface } from "src/app/kernel/notifications/interfaces/notification.interface";
import { Router } from "@angular/router";

@Component({
  selector: 'sxw-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {

  protected notifications: NotificationInterface[] = [];
  protected totalPages = 0;
  protected initiated = false;
  protected loading = true;
  protected page = 1;

  @ViewChild('notificationPanel') notificationPanel!: OverlayPanelComponent;
  @ContentChild('notificationsTriggerTemplate', { static: false }) notificationsTriggerTemplate!: TemplateRef<any>;
  @ContentChild('notificationTemplate', { static: false }) notificationTemplate!: TemplateRef<any>;
  @ContentChild('notificationsHeaderTemplate', { static: false }) notificationsHeaderTemplate!: TemplateRef<any>;

  newNotificationsCount = 0;

  private _unsubscribe = new Subject<void>();

  constructor(
    private notificationService: NotificationService,
    private router: Router
  ) {
  }

  ngOnInit() {
    // Add Firebase token when component initializes
    this.notificationService.addFirebaseToken().pipe(takeUntil(this._unsubscribe)).subscribe();

    // Subscribe to incoming messages
    this.notificationService.messages.subscribe({
      next: (data) => this.addNotification(data.data)
    });

    // Load initial data
    this.loadData();
  }

  // Add a new notification to the list
  addNotification(notification: any) {
    if (!notification) {
      return;
    }
    this.notifications = [notification, ...this.notifications];
    this.newNotificationsCount += 1;
  }

  // Handle click on a notification
  handleNotificationClick(notification: NotificationInterface): any {
    this.markNotificationAsClicked(notification);
    // Perform actions based on the notification type
    switch (notification.type) {
      case 'link': return this.navigateToLink(notification)
    }
  }

  /**
   * Handles the navigation to a link when clicking on a notification.
   * @param notification
   */
  navigateToLink(notification: NotificationInterface) {
    const data = JSON.parse(notification.data);
    if (Array.isArray(data)) {
      this.router.navigate(data);
    } else if (typeof data === 'object') {
      this.router.navigate(data.route, data.options)
    }
  }

  // Mark a notification as clicked
  markNotificationAsClicked(notification: NotificationInterface) {
    if (!notification.id || notification.clicked_at) {
      return;
    }
    this.notificationService.markNotificationAsClicked(notification.id).pipe(takeUntil(this._unsubscribe)).subscribe();
    notification.clicked_at = new Date();
  }

  // Open the notifications panel and mark notifications as seen
  openNotificationsPanel(event: any) {
    this.notificationPanel.toggle(event);
    this.notificationService.markNotificationAsSeen().subscribe();
    this.newNotificationsCount = 0;
  }

  // Load data for notifications
  loadData() {
    this.loading = true;
    this.notificationService.getNotifications(this.page, 8)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((data) => {
        this.loading = false;
        if (!data) { return ; }
        this.initiated = true;
        this.notifications = [...this.notifications, ...data.notifications];
        this.totalPages = data.total / 5;
        this.newNotificationsCount = data.new;
      });
  }

  // Load the next page of notifications
  loadNextPage() {
    this.page += 1;
    this.loadData();
  }

  ngOnDestroy() {
    // Unsubscribe and complete the subject to prevent memory leaks
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
